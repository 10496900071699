import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/Layout";
import moment from "moment";
import { ExportToExcel, ExportToPDF } from "../../Utilities/CommonMethods";

function InsuranceListing() {
  const [insurance, setInsurance] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  // const [selectedStatus, setSelectedStatus]=useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [debouncedStatus, setDebouncedStatus] = useState(status);
  const navigate = useNavigate();
  const [pages, setPages] = useState(1);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const getData = () => {
    const token = localStorage.getItem("token");
    const obj = {
      page: currentPage,
      limit: 8,
    };
    axios
      .post(`${BASE_URL}admin/getAllInsurance`, obj, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setInsurance(response.data.data);
          console.log(response.data.data);

          setPages(response.data.remainingCount);
        } else {
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const delInsurance = (id) => {
    const token = localStorage.getItem("token");
    if (!window.confirm("Do you really want to delete this item?")) {
      return;
    }
    axios
      .delete(`${BASE_URL}admin/deleteInsurance/${id}`, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.error(response.data.message);
          setInsurance([]);
          getData();
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const searchInsurance = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${BASE_URL}admin/searchInsurance`, {
        params: {
          search: searchTerm, // Search by name, mobile number, or vehicle number
          status: status, // Optional: Filter by status
        },
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
          version: "1.0.0",
        },
      });

      if (response.data.success) {
        setInsurance(response.data.data); // Handle the response
      } else {
        setInsurance([]);
      }
    } catch (error) {
      console.error("Error fetching loans:", error);
    }
  };
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      setDebouncedStatus(status);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, status]);

  useEffect(() => {
    if (debouncedSearchTerm !== "" || debouncedStatus !== "") {
      searchInsurance();
    } else {
      getData();
    }
  }, [debouncedSearchTerm, debouncedStatus]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    getData();
  }, [currentPage]);
  return (
    <Layout>
      <div className="content-i">
        <div className="content-box">
          <div className="element-wrapper">
            <div className="element-box">
              <div className="row d-flex justify-content-between">
                <div className="col">
                  <h5 className="form-header text-bold">Insurance Listing</h5>
                  <div className="text-end ">
                    <button
                      className="btn btn-primary"
                      onClick={() => ExportToPDF(insurance, "Insurance")}
                    >
                      Export To PDF
                    </button>
                    {"  "}
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        ExportToExcel(insurance, [
                          "_id",
                          "created_on",
                          "created_by_id",
                          "isDeleted",
                          "createdAt",
                          "__v",
                        ])
                      }
                    >
                      Export To Excel
                    </button>
                  </div>
                  <div className="row d-flex justify-content-between my-4">
                    <div className="col">
                      <input
                        type="text"
                        placeholder="Search by name, mobile, or vehicle number"
                        className="searchLoan"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                    <div className="col-3 text-end">
                      <select
                        className="form-control searchSelect"
                        name="status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        style={{ height: "45px", borderRadius: "0px" }}
                      >
                        <option value="" selected="true">
                          Select Insurance Status
                        </option>
                        <option value="Pending Leed">Pending Leed</option>
                        <option value="Rejected Leed">Rejected Leed</option>
                        <option value="Running Leed">Running Leed</option>
                        <option value="Agree Leed">Agree Leed</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-lightborder">
                  <thead>
                    <tr>
                      <th>Basic Details</th>
                      <th>Vehical Details</th>
                      <th>Policy Details</th>
                      {/* <th>Policy Amount</th> */}
                      <th> Status</th>
                      <th>Created By</th>
                      <th>Modified By</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {insurance?.length > 0 ? (
                      insurance.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {item.name}
                            <br />
                            {item.mobile_no}
                            <br />
                            {item.address}
                            <br />
                            {item.email}
                            <br />
                          </td>

                          <td>
                            {item?.vehicle_type}
                            <br />
                            {item?.model}
                            <br />
                            {item?.vehicle_no}
                            <br />
                            {item?.distt_rto}
                            <br />
                          </td>
                          <td>
                            {/* {item?.insuranceCompany[0]?.company_name}<br/> */}
                            {item?.policyType}
                            <br />
                            {item?.IDV}
                            <br />
                            {item?.policy_no}
                            <br />
                          </td>
                          {/* <td>{item?.policyAmount}</td> */}
                          <td>{item?.status}</td>
                          <td>
                            {item?.created?.by?.name}
                            <br />
                            {moment(item.created?.on).format("DD MMM YYYY")}
                          </td>
                          <td>
                            {item?.modified?.by?.name}
                            <br />
                            {item?.modified?.by?.name &&
                              moment(item.modified?.on).format("DD MMM YYYY")}
                          </td>
                          <td>
                            <button className="border-0 bg-white">
                              <i
                                className="os-icon os-icon-pencil-2"
                                onClick={() =>
                                  navigate("/add-insurance", {
                                    state: { item },
                                  })
                                }
                              />
                            </button>

                            <button
                              className="text-danger border-0 bg-white ps-2"
                              onClick={() => delInsurance(item._id)}
                            >
                              <i className="os-icon os-icon-database-remove" />
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div></div>
                    )}
                  </tbody>
                </table>
              </div>
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li
                    class={`page-item ${currentPage === 1 ? "disabled" : ""} `}
                  >
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Previous"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>

                  {Array.from({ length: pages }, (_, i) => i + 1).map(
                    (page) => (
                      <>
                        <li
                          key={page}
                          className={`page-item ${
                            page === currentPage ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </button>
                        </li>
                      </>
                    )
                  )}
                  <li
                    class={`page-item ${
                      currentPage === pages ? "disabled" : ""
                    }`}
                  >
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Next"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default InsuranceListing;
