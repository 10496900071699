import "./App.css";
import { Suspense, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ThreeCircles } from "react-loader-spinner";
import Dashboard from "./Pages/Dashboard";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./Pages/auth/Login";
import UserList from "./Pages/User/UserList";
import Profile from "./Pages/auth/Profile";
import ChangePassword from "./Pages/auth/ChangePassword";
import AddEmployee from "./Pages/Employees/AddEmployee";
import AddCompany from "./Pages/Company/AddCompany";
import EmployeeListing from "./Pages/Employees/EmployeeListing";
import AddLoan from "./Pages/Loan/AddLoan";
import LoanListing from "./Pages/Loan/LoanListing";
import AddRTO from "./Pages/RTO/AddRTO";
import RTOListing from "./Pages/RTO/RTOListing";
import CompanyListing from "./Pages/Company/CompanyListing";
import ViewLoanCompanyHistory from "./Pages/Loan/ViewLoanCompanyHistory";
import ViewSalary from "./Pages/Employees/ViewSalary";
import GenerateSalary from "./Pages/Employees/GenerateSalary";
import FieldEmployeeSalary from "./Pages/Employees/FieldEmployeeSalary";
import AddRTOAgent from "./Pages/RTO/RTO Agent/AddRTOAgent";
import RTOAgentList from "./Pages/RTO/RTO Agent/RTOAgentList";
import AddDocument from "./Pages/AddDocument/AddDocument";
import AddPayment from "./Pages/RTO/Payment/AddPayment";
import PaymentHistory from "./Pages/RTO/Payment/PaymentHistory";
import AddInsurance from "./Pages/Insurance/AddInsurance";
import InsuranceListing from "./Pages/Insurance/InsuranceListing";
import Reciept from "./Pages/RTO/Payment/Reciept";
import axios from "axios";
import Dashboard2 from "./Pages/Dashboard2";
import SalarySlip from "./Pages/Employees/SalarySlip";
import {setProfile} from "./store/slice/companyProfile";
import { useDispatch } from "react-redux";
import AllPaymentTransfers from "./Pages/Loan/AllPaymentTransfer";
import LedgerTable from "./components/Ledger";
import LoanLedger from "./Pages/Loan/LoanLedgers";
import CommissionSalarySlip from "./Pages/Employees/CommissionSalarySlip";
import CreateExpense from "./Pages/Expenses/CreateExpense";
import ExpenseSlip from "./Pages/Expenses/ExpenseSlip";
import CommissionSalaryList from "./Pages/Employees/CommissionSalaryList";

function App() {
  const dispatch = useDispatch();
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Dashboard />,
    }, {
      path: "/dashboard",
      element: <Dashboard2 />,
    },{
      path: "/login",
      element: <Login />,
    },
    {
      path: "/add-company",
      element: <AddCompany />,
    },{
      path: "/company-listing",
      element: <CompanyListing />,
    },
    {
      path: "/add-employee",
      element: <AddEmployee/>,
    }, {
      path: "/employee-list",
      element: <EmployeeListing/>,
    }, {
      path: "/generate-salary",
      element: <GenerateSalary/>,
    }, {
      path: "/view-salary",
      element: <ViewSalary/>,
    }, {
      path: "/view-Sale-salary",
      element: <FieldEmployeeSalary/>,
    }, {
      path: "/add-loan",
      element: <AddLoan/>,
    },
    {
      path: "/add-loan",
      element: <AddLoan/>,
    },{
      path: "/loan-listing",
      element: <LoanListing/>,
    }, {
      path: "/add-rto",
      element: <AddRTO/>,
    },{
      path: "/rto-listing",
      element: <RTOListing/>,
    }
   ,{
      path: "/user-list",
      element: <UserList/>,
    },{
      path:"/profile",
      element:<Profile/>
    },{
      path:"/change-password",
      element:<ChangePassword/>
    },{
      path:"/history",
      element:<ViewLoanCompanyHistory/>
    },{
      path:"/add-rto-agent",
      element:<AddRTOAgent/>
    },{
      path:"/rto-agent-list",
      element:<RTOAgentList/>
    },{
      path:"/add-doc",
      element:<AddDocument/>
    },{
      path:"/rto-agent-payment",
      element:<AddPayment/>
    },{
      path:"/rto-agent-payment-history",
      element:<PaymentHistory/>
    },{
      path:"/add-insurance",
      element:<AddInsurance/>
    },{
      path:"/insurance-listing",
      element:<InsuranceListing/>
    },{
      path:"/reciept",
      element:<Reciept/>
    },{
      path:"/salary-slip",
      element:<SalarySlip/>
    },{
      path:"/paymentTransfers",
      element:<AllPaymentTransfers/>
    },{
      path:"/ledger",
      element:<LedgerTable/>
    },{
      path:"/loanledger",
      element:<LoanLedger/>
    },{
      path:"/commission-salary",
      element:<CommissionSalarySlip/>
    },{
      path:"/create-expense",
      element:<CreateExpense/>
    },{
      path:"/create-income",
      element:<CreateExpense/>
    },{
      path:"/expense-slip",
      element:<ExpenseSlip/>
    },
    ,{
      path:"/income-slip",
      element:<ExpenseSlip/>
    },{
      path:"/commission-salary-list",
      element:<CommissionSalaryList/>
    }
  ]);

  const viewProfile=()=>{
    const token= localStorage.getItem('token')
    axios
    .get(`${process.env.REACT_APP_BASE_URL}admin/viewCompanyProfile`,{
      headers:{
        "x-access-token":`${token}`,
        "Content-Type": "Application/json",
        version: "1.0.0",
      }
    }).then((response)=>{
      if(response.data.success){
        dispatch(setProfile(response.data.data));
      }
    }).catch((error)=>{
      console.log(error.message);
    })
  }


  useEffect(()=>{
    viewProfile();
  },[])
  
  return (
    <Suspense
      fallback={
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <ThreeCircles
            height="80"
            width="80"
            radius="9"
            color="black"
            ariaLabel="three-dots-loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      }
    >
      <ToastContainer />
 
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
